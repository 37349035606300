import { LogoutDetector } from './logout_detector';

const EXPIRED_TIME = 1 * 60 * 60 * 1000; // 平台后台：同一浏览器1小时未操作自动退出

let detector = new LogoutDetector(EXPIRED_TIME, 1000);
let detectedOperationList = ['keydown', 'mousedown']; // 要检测的动态操作的事件名称

export function startDetector() {
  // 关闭本地和 188 测试环境的自动退出功能
  if (
    process?.env?.VUE_APP_ENV === 'local' ||
    process?.env?.VUE_APP_ENV === 'dev-188'
  ) {
    return null;
  }

  let recorder = detector.getRecorder();

  /**
   * 绑定目标事件的监听
   */
  for (const eventName of detectedOperationList) {
    window.addEventListener(eventName, recorder);
  }

  detector.start();
  recorder();

  return detector;
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';
import { getLoginAdminPower } from '@/utils/api/login/loginApi.js';
import { getAllCategoryInfo } from '@/utils/api/goods/goodsApi.js';
import { NimChat } from '@/pages/chat/js/nim_chat.js';
import { startDetector } from '@/utils/auto_logout';
import {
  addBtnPerms,
  getMyRoutes,
  getDefaultEntrancePage,
} from '../utils/permission_render';

// const myPlatform = () => import('@/pages/platform/myPlatform');

Vue.use(VueRouter);

const routes = [
  {
    //登录页面
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login'),
  },
  {
    path: '/',
    component: () => import('@/pages/layout'), //框架
    redirect: (to) => {
      let auths = JSON.parse(localStorage.getItem('myAuths') || '[]');
      return getDefaultEntrancePage(auths);
    },
  },


  {
    // 开发用的菜单管理页面

    path: '/test/forcoderonly/menu',
    name: 'MenuManagement',
    component: () => import('@/pages/menu-manage/index.vue'),
  },

  {
    // 聊天界面
    path: '/chat',
    name: 'chatMsgWindow',
    meta: {
      isAuth: true,
      closeAuthLoading: true,
    },
    component: () => import('@/pages/chat/index.vue'),
    children: [
      {
        path: ':otherId',
        meta: {
          isAuth: true,
          closeAuthLoading: true,
        },
        component: () => import('@/pages/chat/messageWindow.vue'),
      },
    ],
  },
];
const router = new VueRouter({
  mode: 'history',
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem('token3');

  if (token) {
    // 初始化 nim 聊天实例
    if (!Vue.prototype.$nim) {
      Vue.prototype.$nim = new NimChat(
        localStorage.getItem('admin_id'),
        localStorage.getItem('im_token')
      );
      let isToChat = to.path?.indexOf('/chat') >= 0;
      if (Vue.prototype.$nim.isValid && !isToChat) {
        // 当且仅当不是跳转去聊天界面时，才在这里初始化 Nim
        // 聊天界面单独在页面内初始化
        Vue.prototype.$nim.init();
      }
    }
    // 初始化监听用户动态操作的检测器
    if (!Vue.prototype.$dynamicDetector) {
      Vue.prototype.$dynamicDetector = startDetector();
    }

    let categoryInfo = store.state.categoryData;
    if (categoryInfo.length === 0) {
      let res = await getAllCategoryInfo;
      if (res?.code == 200) {
        store.dispatch('setCategoryInfo', res.data);
      }
    }
  } else {
    // 关闭检测用户动态操作的检测器
    if (Vue.prototype.$dynamicDetector) {
      Vue.prototype.$dynamicDetector.close();
      Vue.prototype.$dynamicDetector = null;
    }
    // 销毁 nim 实例
    if (Vue.prototype.$nim) {
      Vue.prototype.$nim.destroy();
      Vue.prototype.$nim = null;
    }
    store.dispatch('setCategoryInfo', []);
  }
  if (token) {
    // 解决页面刷新，动态路由丢失问题
    if (store.getters.updateAuths) {
      try {
        const params = {};
        // 聊天界面关闭 getLoginAdminPower 的动画
        if (to.meta.closeAuthLoading) {
          params.closeLoading = true;
        }
        let res = await getLoginAdminPower(params);
        if (res?.code == 200) {
          let list = JSON.parse(JSON.stringify(res.data));
          if (list?.length > 0) {
            store.dispatch('setUpdateAuthsFlag', 'HiHelloHejOla');
            store.dispatch('setAuths', list);
            addBtnPerms(list); // 添加按钮权限
            getMyRoutes(list)?.forEach((r) => router.addRoute(r)); // 动态加载路由
            next({ ...to });
          }
        }
      } catch (err) {
        console.error(err);
        // 这一块不能去掉，否则会出现死循环
        if (to.path === '/login') {
          next();
        } else {
          next('/login');
        }
      }
    }
  }
  // 这一块不能去掉，否则会出现死循环
  if (to.path === '/login' || token) {
    next();
  } else {
    next('/login');
  }
});
// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;

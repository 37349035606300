<template>
  <div>
    <el-cascader
      :options="categoryList"
      :props="props"
      collapse-tags
      clearable
      :value="value"
      @input="inputChange($event)"
      :style="{
        width: catewidth + 'px',
      }"
    ></el-cascader>
  </div>
</template>

<script>
// import { getAllCategoryInfo } from '@/utils/api/goods/goodsApi.js';

export default {
  data() {
    return {
      categoryList: [],
      props: {
        multiple: true,
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
      },
      cateArr: [],
    };
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    catewidth: {
      type: Number,
      default: 361,
    },
  },
  mounted() {
    this.getAllCategoryInfo();
  },
  methods: {
    getAllCategoryInfo() {
      this.categoryList = this.$store.state.categoryData;
      // let option = {};
      // getAllCategoryInfo(option)
      //   .then((res) => {
      //     this.categoryList = res.data;
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    inputChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>

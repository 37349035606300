<template>
  <div class="address-selector">
    <el-select
      clearable
      :value="completeAddress"
      popper-class="xm-area-select-popper-box"
      @focus="showDropdown = true"
      ref="selector"
      @clear="clearAddressFunc"
      :placeholder="placeholderKey"
      :popper-append-to-body="false"
      :disabled="disabled"
    >
      <template slot="empty">
        <el-tabs v-show="showDropdown" v-model="tabName" type="border-card">
          <template v-for="(item, index) in curTabPareList">
            <el-tab-pane
              v-if="item.type != 'area'"
              :key="index"
              :label="langType == 'en' ? item.en_label : item.label"
              :name="item.type"
              :disabled="setDisAbled(item.type)"
            >
              <!-- 国家维度特有的 -->
              <template v-if="item.type == 'country'">
                <el-input
                  :placeholder="
                    langType == 'en' ? 'search country' : '搜索国家'
                  "
                  v-model="searchTarget"
                  @input="searchCountry"
                >
                  <img
                    class="search-icon"
                    width="16px"
                    height="16px"
                    slot="prefix"
                    :src="searchIcon"
                  />
                </el-input>
                <!-- 按分组排序的情况 -->
                <div class="country-list-wrapper">
                  <div
                    class="country-groups"
                    v-for="group in addressList.country_list"
                    :key="group.initial"
                  >
                    <div>
                      <span>{{ group.initial }}</span>
                      <hr class="country-group-divider" />
                    </div>
                    <ul>
                      <li
                        v-for="(countryItem, idx) in group.list"
                        :key="idx"
                        :class="{
                          active: addressData.country_id == countryItem.id,
                        }"
                        @click="selectOption(item.type, countryItem)"
                      >
                        {{
                          langType == 'en'
                            ? countryItem.name
                            : countryItem.cname
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-else>
                <ul class="no-search-input">
                  <li
                    v-for="addressItem in addressList[item?.type + '_list']"
                    :key="addressItem.id"
                    :class="{
                      active: addressData[item.type + '_id'] == addressItem.id,
                    }"
                    @click="selectOption(item.type, addressItem)"
                  >
                    {{
                      langType == 'en' ? addressItem.name : addressItem.cname
                    }}
                  </li>
                </ul>
              </template>
            </el-tab-pane>
            <el-tab-pane
              v-if="item.type == 'area' && haveAreas == '1'"
              :key="index"
              :label="langType == 'en' ? item.en_label : item.label"
              :name="item.type"
              :disabled="setDisAbled(item.type)"
            >
              <template>
                <ul class="no-search-input">
                  <li
                    v-for="addressItem in addressList[item?.type + '_list']"
                    :key="addressItem.id"
                    :class="{
                      active: addressData[item.type + '_id'] == addressItem.id,
                    }"
                    @click="selectOption(item.type, addressItem)"
                  >
                    {{
                      langType == 'en' ? addressItem.name : addressItem.cname
                    }}
                  </li>
                </ul>
              </template>
            </el-tab-pane>
          </template>
        </el-tabs></template
      ></el-select
    >
  </div>
</template>
<script>
import {
  getCountries,
  getStates,
  getCity,
  getRegions,
} from '@/utils/api/address/addressApi.js';
export default {
  // inject: [
  //   'country',
  //   'country_id',

  //   'province',
  //   'province_id',
  //   'city',
  //   'city_id',
  // ],
  //isEnglish标识需要返回英文名称
  // props: [
  //   'areaData',
  //   'continentId',
  //   'langType',
  //   'placeholderKey',
  //   'isEnglish',
  //   'en_areaData',
  //   'multipleFlag',
  //   // 与更新事件区分开
  //   // TODO 这里有个问题，就是有用到这个组件的清空事件传出去的都是updata
  //   // 所以先拿这个变量做区分，后续需要改正
  //   'isClear',
  // ],
  data() {
    return {
      showDropdown: false,
      tabName: '',
      searchTarget: '', // 用户搜索框的输入
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标

      /**
       * 这里是全部的标签数据列表
       */
      tabPareList: [
        {
          label: '国家',
          type: 'country',
          en_label: 'country',
        },
        {
          label: '省(州)',
          type: 'province',
          en_label: 'province',
        },
        {
          label: '市',
          type: 'city',
          en_label: 'city',
        },
        {
          label: '区',
          type: 'area',
          en_label: 'area',
        },
      ],
      // -------------------------------------
      addressList: {
        country_list: [], //国家列表
        province_list: [], //省/州列表
        city_list: [], //市列表
        area_list: [], //区列表
      },

      /**
       * 需要传出的数据：
       */
      addressData: {
        //国家
        country: this.countryInfo?.name
          ? this.countryInfo?.name
          : this.countryInfo?.cname || '',
        country_id: this.countryInfo?.id || '',
        countryinfo: this.countryInfo,
        //省
        province: '',
        province_id: '',
        provinceinfo: null,
        //市
        city: '',
        city_id: '',
        cityinfo: null,
        // 区
        area: '',
        area_id: '',
        areainfo: null,
        // 选中最后的数据
        info: null,
      },
      /**
       * 被选中地区对应的英文信息
       */
      en_addressData: {
        en_country: '',
        en_province: '',
        en_city: '',
        en_area: '',
      },
      haveAreas: '-1', // 标记所选国家是否有区
    };
  },
  props: {
    // 控制整个下拉框是否禁选
    disabled: {
      type: Boolean,
      default: false,
    },
    // 默认值
    placeholderKey: {
      type: String,
      default: '请选择',
    },
    // 语言展示
    langType: {
      type: String,
      default: 'zh-cn',
    },
    /**
     * 当前页面可能需要的开始节点
     */
    startAddress: {
      type: String,
      default: 'country',
    },
    /**
     * 地址选择结束节点
     */
    endAddress: {
      type: String,
      default: 'city',
    },
    /**
     * 是否拿指定大洲下的国家列表
     * 这个变量有值的情况下，开始节点会默认为 国
     */
    continentId: {
      type: [String, Number],
      default: '',
    },
    /**
     * 这个属性有值的情况下，会默认开始节点固定为 省
     */
    countryId: {
      type: [String, Number],
      default: '',
    },
    /**
     * countryId 有值的时候，(必填)
     * 国家首字母
     */
    countryInitial: {
      type: String,
      default: '',
    },
    /**
     * 这个属性是配合countryId 使用的，因为开始节点为省的情况下，目前不会再考虑拿国家列表了，所以自行传国家信息
     */
    countryInfo: {
      type: Object,
      default: null,
    },
    // 是每级都筛选，还是只筛选最后一级
    isSelectedLast: {
      type: Boolean,
      default: false,
    },
    /**
     * 是否需要展示区标签
     */
    hasAreaTab: {
      type: Boolean,
      default: false,
    },
    /**
     *  国家类型
     * 这个目前的作用主要取决于要不要展示中国（后续可拓展只拿欧盟国家）
     * 默认为空，拿全部国家
     * 有中国：china   没有中国：international
     */
    countryType: {
      type: String,
      default: '',
    },
    // -------------------------------------------------
    // 数据回显
    echoAreaData: {
      type: Object,
      default: null,
    },
    echoEnAreaData: {
      type: Object,
      default: null,
    },
  },

  computed: {
    // 显示在搜索框内部的当前选中的完整地址信息
    completeAddress() {
      const typeList = this.getDataType();
      let addr = '';
      for (let i = 0; i < typeList?.length; i++) {
        if (this.addressData?.[typeList[i]]) {
          if (i == 0) {
            addr += this.addressData?.[typeList[i]];
          }
          if (i > 0) {
            addr += '/' + this.addressData?.[typeList[i]];
          }
        }
      }
      return addr;
    },
    /**
     * 当前所使用的开始节点
     * 后续如果需要这个可以放进data里面当个可控制变量
     */
    curStartAddress() {
      if (this.countryId != '') {
        return 'province';
      }
      return this.startAddress;
    },
    /**
     * 当前的标签列表有开始节点（curStartAddress）和结束节点（endAddress）决定
     * 例子 国 --> 市
     */
    curTabPareList() {
      // 开始节点
      let startIdx = this.tabPareList?.findIndex(
        (item) => item.type == this.curStartAddress
      );
      // 结束节点
      let endIdx = this.tabPareList?.findIndex(
        (item) => item.type == this.endAddress
      );
      // 如果结束节点不存在，则拿标签长度
      if (endIdx == '-1') {
        endIdx = this.tabPareList.length;
      }
      // 开始节点和结束节点差值
      let rangeVal = endIdx - startIdx;
      let list =
        JSON.parse(
          JSON.stringify(
            this.tabPareList?.splice(startIdx, rangeVal + 1) || []
          ) || []
        ) || [];

      return list || [];
    },
    /**
     * 标签长度，因为出现一个情况，没区列表的时候，标签块不展示区。，那么标签长度有差异
     */
    tabListLength() {
      if (this.hasAreaTab && this.haveAreas == '1') {
        return 4;
      }
      return 3;
    },
  },

  methods: {
    /**
     * 获取当前需要的地区类型数组,如有拓展，自行添加
     * @param {String} changeStart 是否改变开始节点
     */
    getDataType(changeStart = '') {
      let options = ['country', 'province', 'city', 'area'];
      // 开始节点
      let startIdx = 0;
      if (changeStart == '') {
        startIdx = options?.findIndex((item) => item == this.curStartAddress);
      } else {
        startIdx = options?.findIndex((item) => item == changeStart);
      }
      let curOptionsList = options.splice(startIdx, options.length);
      return curOptionsList;
    },
    // ------------------------------------------------------------------------
    // 交互
    /**
     * 设置标签禁止状态
     * @param {String} type 地址类型
     * 对应标签的列表没有数据的情况下，不给选
     */
    setDisAbled(type) {
      if (this.addressList?.[type + '_list']?.length > 0) {
        return false;
      }
      return true;
    },
    /**
     * 点击x清空选择地址
     * 将数据恢复成初始化
     */
    clearAddressFunc() {
      const options = this.getDataType();
      // 首选被选中的地址应该全部清空（需要传出的数据）
      // 其次，将除了开始节点（curStartAddress）标签下数据保留，其余标签的列表数据清空
      // 最后将标签回正到开始节点

      for (let i = 0; i < options?.length; i++) {
        this.addressData[options[i]] = '';
        this.addressData[options[i] + '_id'] = '';
        this.en_addressData['en_' + options[i]] = '';
        if (this.curStartAddress != options[i]) {
          this.addressList[options[i] + '_list'] = [];
        }
      }
      this.changeTab(this.curStartAddress);

      this.$emit('clear', this.addressData, this.en_addressData);
    },
    // 切换标签
    changeTab(val) {
      this.tabName = val;
    },

    /**
     * 用户点击下拉菜单的选项触发的函数
     * 目前这里的一切逻辑都指向，选中最后一个才传值出去
     * type：点击的哪个菜单 -> country：国家菜单；province：省份菜单；city：城市菜单; area:区
     * item: 点击的哪个选项
     * isEmit 是否需要编辑（传值出去）
     */
    async selectOption(type, item, isEmit = true) {
      // 控制是否触发 closeDropdown 方法
      let flag = false;
      // 首先，选中数据时候会有如下数据处理
      // 1.将当前选中的标签后面对应的数据全部清空，例子：选国家数据，对应后面的省，市，区等数据要清空
      this.clearInfo(type);
      // 2.将对应选中的数据赋值给对应变量
      // 给选中的当前数据分类
      item.type = type;
      this.addressData[type] = this.langType == 'en' ? item.name : item.cname;
      this.en_addressData['en_' + type] = item.name;
      this.addressData[type + '_id'] = item.id;
      this.addressData[type + 'info'] = item || null;

      // 取最后的值
      this.addressData.info = item || null;

      // 如果当前选的是末尾，则直接传值出去
      if (this.endAddress == type) {
        flag = true;
      } else {
        // 3.对应交互
        if (type == 'country') {
          if (this.hasAreaTab) {
            this.haveAreas = item.have_areas;
          }
          // 选择国家
          if (item.has_state == '1') {
            let res = await this.getprovinceList();
            // 如果点击国家有省的话就不把值传出去
            if (res?.length > 0) {
              flag = false;
            } else {
              flag = true;
            }
          } else if (item.has_state == '0') {
            let res = await this.getcityList();
            if (res?.length > 0) {
              flag = false;
            } else {
              flag = true;
            }
          }
        } else if (type == 'province') {
          let res = await this.getcityList();
          if (res?.length > 0) {
            flag = false;
          } else {
            flag = true;
          }
        } else if (type == 'city') {
          if (this.haveAreas == 1) {
            // 有区
            this.getareaList();
          } else {
            // 可以传出数据了
            flag = true;
          }
        } else if (type == 'area') {
          // 可以传出数据了
          flag = true;
        }
      }

      // 如果是每级都要进行筛选情况
      if (!this.isSelectedLast) {
        flag = true;
      }
      if (flag) {
        this.closeDropdown(isEmit);
      }
    },

    // -----------------------------------------------------------
    // 数据处理相关
    // 根据选中的标签，清空后面的数据
    clearInfo(type) {
      const options = this.getDataType();
      // 找出type 对应的位置
      let idx = options?.findIndex((item) => item == type);
      // 拿到后面的标签数据
      if (idx > -1) {
        let needTypeList = options.splice(idx + 1, options.length);
        for (let i = 0; i < needTypeList?.length; i++) {
          this.addressData[needTypeList[i]] = '';
          this.addressData[needTypeList[i] + '_id'] = '';
          this.addressData[needTypeList[i] + 'info'] = null;
          this.en_addressData['en_' + needTypeList[i]] = '';
          if (this.curStartAddress != needTypeList[i]) {
            this.addressList[needTypeList[i] + '_list'] = [];
          }
        }
        this.addressData.info = null;
      }
    },
    // 关闭下拉，并且将选中的值传出去
    closeDropdown(isEmit) {
      if (isEmit) {
        this.$emit('update', this.addressData, this.en_addressData);
      }

      this.showDropdown = false;
      this.$refs.selector && this.$refs.selector.blur(); // 选择器的尖角符号向下
    },

    // 搜索国家，高亮用户输入数据后的首字母
    async searchCountry() {
      let that = this;
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
        id: this.continentId ? this.continentId : '',
      };
      if (this.countryType != '') {
        options.type_rote = this.countryType;
      }
      try {
        let res = await getCountries(options);
        if (res.code == 200) {
          that.addressList.country_list = res.data;
          return;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取国家列表
    async getcountryList() {
      let that = this;
      const options = {
        is_initial_group: 1,
        id: this.continentId ? this.continentId : '',
      };
      if (this.countryType != '') {
        options.type_rote = this.countryType;
      }
      try {
        let res = await getCountries(options);
        if (res.code == 200) {
          that.addressList.country_list = res.data;
          return res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取省列表
    getprovinceList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.countryId ? this.countryId : this.addressData.country_id,
        };
        try {
          let res = await getStates(options);
          if (res.code == 200) {
            that.addressList.province_list = res.data;
            that.changeTab('province');
            resolve(res.data);
            return;
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    // 获取市列表
    getcityList() {
      return new Promise(async (resolve, reject) => {
        // 触发这个方法前如果省有数据则，父级为省，省没数据，父级为国
        const upperType =
          this.addressData.province_id && this.addressData.province_id != '0'
            ? ''
            : 'country';
        const upperId =
          upperType != 'country'
            ? this.addressData.province_id
            : this.addressData.country_id;
        const options = {
          id: upperId,
          type: upperType,
        };
        let that = this;
        try {
          let res = await getCity(options);
          if (res.code == 200) {
            that.addressList.city_list = res.data;
            if (res.data?.length > 0) {
              that.changeTab('city');
            }
            resolve(res.data);
            return;
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    // 获取区列表
    getareaList() {
      let that = this;
      return new Promise(async (resolve, reject) => {
        const options = {
          id: this.addressData.city_id,
        };
        try {
          let res = await getRegions(options);
          if (res.code == 200) {
            that.addressList.area_list = res.data;
            that.changeTab('area');
            resolve(res.data);
            return;
          }
        } catch (error) {
          console.error(error);
          reject(error);
        }
      });
    },
    // 重置并清空数据
    resetData() {
      let type = this.getDataType();
      for (let i = 0; i < type?.length; i++) {
        this.addressData[type[i]] = '';
        this.addressData[type[i] + '_id'] = '';
        this.addressData[type[i] + 'info'] = null;
        this.addressData.info = null;
      }
      this.en_addressData = {
        en_country: '',
        en_province: '',
        en_city: '',
        en_area: '',
      };
      this.searchTarget = '';
      this.tabName = '';
      const addressType = this.getDataType();
      for (let i = 0; i < addressType?.length; i++) {
        if (this.curStartAddress != addressType[i]) {
          this.addressList[addressType[i] + '_list'] = [];
        }
      }
      this.changeTab(this.curStartAddress);
    },
    // 地址筛选回显
    async getEchoArea(info) {
      let res = await this.getcountryList();
      const options = this.getDataType();
      for (let i = 0; i < options?.length; i++) {
        if (info?.[options[i]]) {
          // 如果是国家
          if (options[i] == 'country') {
            for (let j = 0; j < res?.length; j++) {
              let list = res[j]?.list;
              for (let l = 0; l < list?.length; l++) {
                if (
                  list[l].id == info?.[options[i] + 'Id'] ||
                  list[l].id == info?.[options[i] + '_id']
                ) {
                  this.selectOption(options[i], list[l], false);
                  continue;
                }
              }
            }
          } else {
            const obj = {
              cname: info?.[options[i]],
              id: info?.[options[i] + 'Id']
                ? info?.[options[i] + 'Id']
                : info?.[options[i] + '_id'],
              name: info?.['en_' + options[i]],
            };
            this.selectOption(options[i], obj, false);
            continue;
          }
        }
      }
    },
    /**
     * 获取初始数据
     * 目前只考虑到 最高国维度的
     */
    async initInfoData() {
      // 首先拿全部地区类型数组
      let typeArr = this.getDataType('country') || [];
      // 接着获取当前开始节点的下标
      let startIdx = typeArr?.findIndex((item) => item == this.curStartAddress);
      // 然后获取开始节点前面的地区类型数组
      if (startIdx == 0) {
        startIdx = startIdx + 1;
      }
      let needTypeArr = typeArr.splice(0, startIdx);
      if (needTypeArr?.length > 0) {
        // 拿首位的地址数据
        let res = await this['get' + needTypeArr[0] + 'List']();
        this.getCurData(needTypeArr[0], res, needTypeArr);
      }
    },
    /**
     * 拿对应数据,并进行对应操作
     */
    getCurData(strType, dataArr, typeArr) {
      // 国家维度的数据
      if (strType == 'country') {
        let info =
          dataArr?.filter(
            (item) => item.initial == this.countryInitial.toLocaleUpperCase()
          )?.[0] || [];
        let countryList = info?.list || [];
        let countryInfo =
          countryList?.filter((item) => item.id == this.countryId)?.[0] || {};
        this.selectOption(strType, countryInfo, false);
      }
    },
  },
  beforeDestroy() {
    this.resetData();
  },
  async mounted() {
    // 获取初始数据
    await this.initInfoData();
    this.changeTab(this.curStartAddress);
    this.$nextTick(() => {
      if (this.echoAreaData || this.echoEnAreaData) {
        let echoData = { ...this.echoAreaData, ...this.echoEnAreaData };
        this.getEchoArea(echoData);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
$tab-width: 100px;
$select-height: 40px;
$highlight-color: #026bff;
.address-selector {
  .xm-area-select-popper-box {
    position: relative;
    // width: $tab-width * 3;
    width: 200px;

    * {
      border: 0px;
    }
    .el-select {
      height: 40px;
    }
    ul {
      padding-left: 1em; // 选项名称距离左边的距离
    }

    li {
      list-style-type: none;
      padding-top: 5px;
      padding-bottom: 5px;
      cursor: pointer;
    }

    li.active,
    span.active {
      color: $highlight-color;
    }
    li:hover {
      color: $highlight-color;
    }
    .el-tabs {
      visibility: visible;
      position: absolute;
      top: -8px;
      left: -5px;
      z-index: 2; // 必须比原本的下拉菜单大

      width: calc($tab-width * v-bind(tabListLength));

      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

      * {
        font-size: 14px;
        color: #666;
        line-height: 1.5 !important;
      }
      ::v-deep .el-input {
        margin-bottom: 10px;
        .el-input__inner {
          width: calc($tab-width * v-bind(tabListLength) - 20px);
          margin-left: -5px;
          border-radius: 0px 0px 0px 0px;
          background-color: #f7f7f7;

          font-size: 12px;
          color: #808080;
          border: 1px solid #ccc;
        }
      }
    }

    .no-search-input {
      height: 290px;
      overflow: auto;
      padding-left: 5px;

      &::-webkit-scrollbar-track {
        background: none;
      }
    }

    .search-icon {
      display: inline-block;
      height: 40px;
      line-height: 40px;
    }

    .el-tabs--border-card {
      ::v-deep .el-tabs__header {
        border: 0px;
        // 解决高亮标签页两侧有1px缝隙
        .is-active {
          border-left-color: transparent;
          border-right-color: transparent;
        }
        // 调整标签页宽度，宽度相同且文本居中
        .el-tabs__item {
          width: $tab-width;
          text-align: center;
          color: #808080;
          // 解决“城市”标签页头右侧有1px缝隙
          &:nth-last-of-type(1) {
            width: $tab-width + 1;
          }
          // 解决点击和悬浮标签页，标签页文字蓝色高亮问题
          &:hover {
            color: #4d4d4d;
          }
        }
        .is-top {
          background-color: #f2f2f2;
          color: #808080;
        }
        .is-active {
          color: #4d4d4d;
          background-color: #ffffff;
          color: #4d4d4d;
        }
      }
      ::v-deep .el-tabs__content {
        padding-bottom: 10px;
        padding-right: 2px; // 解决滚动条不贴右边问题（有 2px 缝隙）
        padding-top: 10px;
        height: 310px;
      }
    }

    // 隐藏标签页前后的滑动提示箭头
    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      display: none;
    }

    .country-list-wrapper {
      height: 240px;
      overflow: auto;
      width: max-content;
      &::-webkit-scrollbar-track {
        background: none;
      }
    }
    .country-groups {
      & > span {
        color: #666;
        padding-right: 5px;
        margin-top: 5px;
      }
      & > span.active {
        color: $highlight-color;
      }
      // 国家按拼音分组，分组的水平分割线
      .country-group-divider {
        border: 1px solid #e6e6e6;
        display: inline-block;
        width: calc($tab-width * v-bind(tabListLength) - 50px);

        margin-bottom: 0.33em;
      }
    }
  }
}
</style>

<template>
  <div class="addressinsure">
    <el-select
      v-model="countvalue"
      placeholder="请选择"
      multiple
      popper-class="xm-address-multi-select"
      clearable
    >
      <div>
        <div class="topbar">
          <div class="toptad">
            <div class="counttext">国家</div>
          </div>
          <div class="searchinput">
            <el-input
              placeholder="搜索国家"
              v-model="searchTarget"
              @input="getCountryList()"
            >
              <img width="16px" height="16px" slot="prefix" :src="searchIcon" />
            </el-input>
          </div>
        </div>
        <div class="optionmodel">
          <!-- <el-option style="display: none"></el-option> -->
          <div v-if="!hideAll">
            <el-checkbox v-model="allvalue" class="allmodel" @change="allregson"
              >全球</el-checkbox
            >
          </div>
          <el-checkbox-group v-model="countvalue">
            <!-- <div v-for="item in country_list" :key="item.initial"> -->
            <div v-for="item in country_list" :key="item.initial">
              <div v-if="item.list.length > 0">
                <div class="opitemtop">
                  <div>{{ item.initial }}</div>
                  <div></div>
                </div>
                <div v-for="res in item.list" :key="res.id">
                  <el-option :label="res.cname" :value="res.id">
                    <div @click.stop>
                      <el-checkbox :label="res.cname" @change="itemoption">
                        {{ res.cname }}
                      </el-checkbox>
                    </div>
                  </el-option>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div style="overflow: hidden; width: 100%">
          <div class="bottommodel">
            <div class="bottomlabel">已选择国家:</div>
            <div
              class="bottomvalue"
              v-for="(item, index) in countvalue"
              :key="item"
            >
              {{ item }} <span class="hand" @click="cancelitwm(index)">x</span>
            </div>
          </div>
        </div>
      </div>
    </el-select>
  </div>
</template>

<script>
import { getCountries } from '@/utils/api/address/addressApi.js';
export default {
  name: 'XmAddressMultiSelect',
  data() {
    return {
      country_list: [],
      searchTarget: '',
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      countvalue: [],
      conutryid: [],
      countrydimen: [], // 一维城市数组
      allvalue: false,
      countryName: [],
      // sumWidth: 0,
    };
  },
  mounted() {
    this.getCountryList();
  },
  props: {
    countIds: {
      type: Array,
      require: true,
      default: () => [],
    },
    allTag: {
      type: [String, Number],
      default: 0,
    },
    // 是否隐藏“全球”选项
    hideAll: {
      type: Boolean,
      default: false,
    },
    //标识
    flag: {
      type: String,
      default: '',
    },
    //已选中国家(进行删除过滤)
    selectedCountryArr: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    countvalue(value) {
      this.conutryid = [];
      this.countryName = [];
      for (let i = 0; i < this.countrydimen.length; i++) {
        if (value.indexOf(this.countrydimen[i].cname) > -1) {
          this.conutryid.push(this.countrydimen[i].id);
          this.countryName.push(this.countrydimen[i].cname);
        }
      }
      if (value[0] == '全球') {
        this.conutryid = [this.allTag];
      }
      if (value.indexOf('全球') > -1) {
        this.allvalue = true;
      } else {
        this.allvalue = false;
      }
      this.$emit('getDataEvent', this.conutryid, this.countryName, this.flag);

      // this.$nextTick(() => {
      //   console.log(
      //     document.getElementsByClassName('el-select__tags')[0].offsetWidth,
      //     '3434'
      //   );
      //   const tagArr =
      //     document.getElementsByClassName('el-select__tags')[0].children[0]
      //       .children;
      //   let sumWidth = 0;
      //   for (const tag of tagArr) {
      //     console.log('tag.offsetWidth', tag.offsetWidth);
      //     sumWidth += tag.offsetWidth;
      //   }
      //   console.log('sum', sumWidth);
      //   this.sumWidth = sumWidth;
      // });
    },
    // countIds(val) {
    //   // 回显id转国家名
    //   if (val.length > 0 && this.countrydimen.length > 0) {
    //     this.countvalue = this.echoCountyr(val);
    //   }
    // },
    selectedCountryArr() {
      this.filterSelectedCountry(this.country_list);
    },
  },
  methods: {
    async getCountryList() {
      const options = {
        is_initial_group: 1,
        name: this.searchTarget,
      };
      let that = this;
      try {
        let res = await getCountries(options);
        if (res.code == 200) {
          that.country_list = this.filterSelectedCountry(res.data);
          that.country_list.forEach((item) => {
            this.countrydimen = this.countrydimen.concat(item.list);
          });
          if (this.countIds.length > 0) {
            this.countvalue = this.echoCountyr(this.countIds);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    searchCountry() {
      if (this.searchTarget) {
        let arr = [];
        this.country_list.forEach((item) => {
          let filterarr = [];
          filterarr = item.list.filter((res) => {
            return res.cname.indexOf(this.searchTarget) > -1;
          });
          arr.push({
            initial: item.initial,
            list: filterarr,
          });
        });
        return arr;
      } else {
        return this.country_list;
      }
    },
    cancelitwm(index) {
      this.countvalue.splice(index, 1);
    },
    allregson(val) {
      if (val) {
        this.conutryid = [this.allTag];
        this.countvalue = ['全球'];
      } else {
        this.conutryid = [];
        this.countvalue = [];
      }
    },
    itemoption() {
      this.allvalue = false;

      this.conutryid = this.conutryid.filter((item) => {
        return item != 0;
      });
      this.countvalue = this.countvalue.filter((item) => {
        return item != '全球';
      });
    },
    echoCountyr(arr) {
      let nameArr = [];
      if (arr.indexOf(this.allTag) > -1) {
        // 全球
        nameArr = ['全球'];
      } else {
        for (let i = 0; i < this.countrydimen.length; i++) {
          if (arr.indexOf(this.countrydimen[i].id) > -1) {
            nameArr.push(this.countrydimen[i].cname);
          }
        }
      }
      return nameArr;
    },
    filterSelectedCountry(data = []) {
      if (!this.selectedCountryArr.length) return data;
      data.forEach((item) => {
        if (Array.isArray(item.list)) {
          item.list = item.list.filter(
            (itemTwo) =>
              !this.selectedCountryArr.some(
                (itemThree) => Number(itemThree) === Number(itemTwo.id)
              )
          );
        }
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
.xm-address-multi-select {
  .el-select-dropdown__wrap {
    max-height: 1000px;
  }
  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    text-align: left;
  }
  .el-scrollbar__wrap {
    max-height: 438px !important;
  }
  .el-scrollbar__thumb {
    display: none;
  }
  .el-select-dropdown__list {
    width: 300px;
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.toptad {
  width: 100%;
  height: 36px;
  background: #e6e6e6;
}

.addressinsure ::v-deep .el-select-dropdown__list {
  padding: 0;
}

.addressinsure ::v-deep .el-popper[x-placement^='bottom'] .popper__arrow {
  display: none;
}
.counttext {
  width: 100px;
  height: 36px;
  background: #ffffff;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 36px;
  text-align: center;
}

.addressinsure ::v-deep .el-select {
  width: 300px;
  height: 40px;
}

.addressinsure ::v-deep .el-select__tags {
  flex-wrap: wrap !important;
  overflow-x: hidden !important;
  height: 40px;
  overflow: hidden;
  .el-tag {
    margin: 8px 0;
    margin-left: 10px;
    &:first-of-type {
      margin-left: 12px;
    }
  }
}

// .addressinsure ::v-deep .el-select__tags:after {
//   content: '......';
//   position: absolute;
//   top: -3px;
//   right: 28px;
// }

// .addressinsure ::v-deep .el-input--suffix .el-input__inner:after {
//   content: '......';
//   position: absolute;
//   top: -3px;
//   right: 28px;
// }

.addressinsure ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.addressinsure ::v-deep .el-scrollbar {
  height: fit-content;
}

.searchinput {
  box-sizing: border-box;
  padding: 10px;
}

.searchinput ::v-deep .el-input--prefix .el-input__inner {
  border-radius: 0px;
  background: #e6e6e6;
  border-color: #cccccc;
}

.searchinput ::v-deep .el-input__prefix {
  top: 13px;
  left: 13px;
}

.optionmodel {
  height: 242px;
  overflow: auto;
}

.allmodel {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.opitemtop {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 10px;
}

.opitemtop > :first-child {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.opitemtop > :last-child {
  width: 248px;
  height: 1px;
  background: #e6e6e6;
  margin: auto;
  margin-left: 10px;
}

.bottommodel {
  box-sizing: border-box;
  margin: 0 10px 20px 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid #e6e6e6;
  overflow-y: auto;
  overflow-x: hidden;
  // max-height: 88px;
  margin-right: -12px;
  // min-height: 50px;
  height: 68px;
}

.bottommodel > div {
  height: 24px;
}

.bottomlabel {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
  margin-right: 10px;
  line-height: 24px;
  margin-top: 6px;
}

.bottomvalue {
  background: #e6f1ff;
  margin-right: 10px;
  padding: 0px 5px;
  line-height: 24px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #026bff;
  // margin-bottom: 5px;
  margin-top: 10px;
}

.hand {
  cursor: pointer;
}

.addressinsure ::v-deep .el-select-dropdown__wrap {
  max-height: 1000px;
}

.addressinsure
  ::v-deep
  .el-select-dropdown.is-multiple
  .el-select-dropdown__item {
  text-align: left;
}
</style>

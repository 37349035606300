<template>
  <div
    class="tableStyle"
    :class="{
      isBottomBorder: isBottomBorder,
      isLevelScrollBar: isLevelScrollBar,
      'adapt-low-version': lowVersion,
    }"
    :style="{
      '--xm-table-bg': tableBackground,
      '--bottomBorderColor': bottomBorderColor,
    }"
  >
    <div v-if="isCheckbox">
      <div class="derive" v-if="isDerive">
        <slot name="searchFormSlot"></slot>
        <div class="derive-item">
          <div class="derive-label">
            已选择：<span>{{ checkedItem.length }}</span>
          </div>
          <slot name="deriveBtnSlot" v-if="isDeriveBtnSlot">
            <span
              class="derive-btn"
              @click="exportDetails"
              v-permission="{ action: isExportKey }"
              >{{ exportBtnLabel }}</span
            ></slot
          >
        </div>
        <div class="customBtn-box" v-if="!isDeriveBtnSlot">
          <slot name="customBtnSlot"></slot>
        </div>
        <div class="moreFieldList-box" v-if="isMoreFieldList">
          <slot name="moreFieldListSlot"></slot>
        </div>
      </div>
      <slot name="customChoose" v-else></slot>
      <div class="allCheckbox" v-if="needAllCheck">
        <el-checkbox
          :disabled="table.length > 0 ? false : true"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
      </div>
    </div>
    <slot v-else name="customChoose"></slot>
    <el-checkbox-group v-model="checkedItem" @change="handlecheckedItemChange">
      <el-table
        v-sticky-header="{ offsetTop: '60px' }"
        :data="table"
        :max-height="tableHeight"
        :header-cell-class-name="headerStyleFunc"
        :cell-class-name="hasTableRowClassName ? tableRowClassName : ''"
        @sort-change="sortChangeFunc"
        class="table"
        :span-method="objectSpanMethod"
        :show-summary="isTotal"
        ref="elTable"
      >
        <el-table-column
          v-for="(item, index) in tableFiled"
          :key="item.filed + index + tableKey + item.text"
          :label="item.text"
          :prop="item.filed"
          :sortable="item.isSort ? 'custom' : false"
          :min-width="item.width"
          :fixed="item.fixed"
        >
          <template
            slot="header"
            slot-scope="{ row, column, $index }"
            v-if="item.filed"
          >
            <slot
              v-if="item.isHeaderEdit"
              :row="{ row, column, $index }"
              :name="item.filed + 'HeaderSlot'"
            ></slot>
            <span
              v-else
              :class="{
                marginLeft28:
                  isCheckbox &&
                  needAllCheck &&
                  index == 0 &&
                  firstHeaderSkewing &&
                  firstHeaderSkewing,
              }"
              >{{ item.text }}</span
            >
          </template>
          <template slot-scope="{ row, column, $index }" v-if="item.filed">
            <div :class="{ isFlex: index === 0 }">
              <el-checkbox
                :label="setFiledName(row)"
                v-if="index === 0 && isCheckbox"
                :disabled="isItemDisabled(row[checkboxFiledName])"
                >{{ '' }}</el-checkbox
              >
              <slot
                :row="{ row, column, $index }"
                :name="item.filed + 'Slot'"
                v-if="item.isEdit"
              ></slot>
              <!-- item.line 控制展示几行溢出隐藏并悬浮展示,目前有一行＆两行,item.line值为one/two -->
              <span
                v-else
                :class="item?.line ? item?.line + '-line' : ''"
                :title="item?.line ? row[item.filed] : ''"
                >{{ row[item.filed] || '--' }}</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  computed: {
    itemOptions() {
      if (this.checkboxFiledName === '') {
        return this.table.map((item) => item);
      } else {
        return this.table.map((item) => item[this.checkboxFiledName]);
      }
    },
    isDisabledFunc() {
      return function (id, index) {
        if (
          this.maxSelectedCount == this.checkedItem.length &&
          this.maxSelectedCount > 0
        ) {
          return this.table.some((item) =>
            this.checkedItem.some(
              (itemTwo) => itemTwo == item[this.checkboxFiledName]
            )
          );
        }
        return false;
      };
    },
  },
  props: {
    table: {
      type: Array,
      default: () => [],
    }, //表格数据
    tableFiled: {
      type: Array,
      default: () => [],
    }, //列配置
    isCheckbox: {
      //是否有多选功能
      type: Boolean,
      default: false,
    },
    isDerive: {
      //是否导出
      type: Boolean,
      default: false,
    },
    firstHeaderSkewing: {
      //表头第一个是否偏移
      type: Boolean,
      default: true,
    },
    checkboxFiledName: {
      //复选绑定的字段名字
      type: String,
      default: '',
    },
    checkboxSelected: {
      //控制复选选中数组
      type: Array,
      default: () => [],
    },
    checkboxSelectedObj: {
      //控制复选选中数组对象
      type: Array,
      default: () => [],
    },
    exportBtnLabel: {
      type: String,
      default: '导出明细',
    },
    isBottomBorder: {
      //是否要下边框
      type: Boolean,
      default: false,
    },
    isLevelScrollBar: {
      //是否有横线滚动条
      type: Boolean,
      default: false,
    },
    tableHeight: {
      //限制表格最大高度  溢出显示滚动条（表头固定）
      type: [Number, String],
      default: 'auto',
    },
    tableBackground: {
      //背景顔色
      type: String,
      default: '#f5f5f5',
    },
    //设置下边框的边框颜色
    bottomBorderColor: {
      type: String,
      default: '#EBEEF5',
    },
    //返回的数组里面是否要是table中的item对象
    isReturnObj: {
      type: Boolean,
      default: false,
    },
    //当数据量过多限制全选一次性选中最大个数
    maxSelectedCount: {
      type: Number,
      default: 0,
    },
    isMergeMode: {
      type: Boolean,
      default: false,
    },
    // 是否允许取消选中原本已选中的复选框
    isCancelCheckedAllowed: {
      type: Boolean,
      default: true,
    },
    /**
     * 如果要自定义批量按钮等操作的话，就用这个变量
     */
    isDeriveBtnSlot: {
      type: Boolean,
      default: true,
    },
    // 控制显示更多字段
    isMoreFieldList: {
      type: Boolean,
      default: false,
    },
    //判断导出是否有权限控制(空表示无权限控制)
    isExportKey: {
      type: String,
      default: '',
    },
    isTotal: {
      type: Boolean,
      default: false,
    },
    // 某些时候，表格的字段配置不会实时刷新
    // 因为 el-table-column 上设置的 key 值不唯一
    // 这个字段就是用于防止 key 值不唯一
    tableKey: {
      type: String,
      default: '',
    },
    // 是否需要隔行换色
    hasTableRowClassName: {
      type: Boolean,
      default: true,
    },
    // 是否需要全选按钮
    needAllCheck: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkAll: false, //全选按钮
      checkedItem: [], //复选选中项id
      checkedObjItem: [], //不指定复选绑定值存储数组

      // 原本已被选中的数组
      originalCheckedSet: new Set(),

      lowVersion: false, // 是否是低版本浏览器,默认否
    };
  },
  methods: {
    // 检测低版本浏览器
    browserVersion() {
      let version = null;
      if (navigator.userAgent) {
        let arr = navigator.userAgent.split(' ');
        arr.forEach((item) => {
          if (item.indexOf('Chrome/') > -1) {
            version = Number(item.split('/')[1].split('.')[0]);
          }
        });
        if (version && version < 95) {
          this.lowVersion = true;
        }
      }
    },
    //合并单元格内部处理回调
    getSpanArr(data, spanKey) {
      let spanArr = [];
      let pos = '';
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          spanArr.push(1);
          pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i][spanKey] === data[i - 1][spanKey]) {
            spanArr[pos] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            pos = i;
          }
        }
      }
      return spanArr;
    },
    //合并单元格处理回调
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        // 合并第一列
        if (this.isMergeMode) {
          let spanArr = this.getSpanArr(this.table, column.property);
          const _row = spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
        return {
          rowspan: 1,
          colspan: 1,
        };
      }
    },
    setFiledName(obj) {
      return obj[this.checkboxFiledName];
    },
    //排序触发回调
    sortChangeFunc({ column, prop, order }) {
      this.$emit('getSortDataEvent', prop, order);
    },
    // 重置表格排序
    resetTableSort() {
      this.$refs.elTable.clearSort();
    },
    // table头部样式
    headerStyleFunc() {
      return 'xm-table-header-bg';
    },
    //选中全选触发
    handleCheckAllChange(isSelected) {
      if (this.checkedItem.length) {
        let notCurDataArr = this.notCurrentDataFunc(true);
        if (isSelected) {
          this.checkedItem = [...notCurDataArr, ...this.itemOptions];
        } else {
          this.checkedItem = [...notCurDataArr];
        }
      } else {
        if (isSelected) {
          this.checkedItem = this.itemOptions;
        }
      }
      if (this.maxSelectedCount) {
        this.checkedItem = this.checkedItem.slice(0, this.maxSelectedCount);
      }
      let returnArray = [];
      if (this.isReturnObj) {
        returnArray = this.getIdObj();
        //旧的复选数组this.checkboxSelected小于当前this.checkedItem数组长度，表示添加数据
        if (this.checkboxSelected.length < this.checkedItem.length) {
          //添加，防止分页添加造成this.checkboxSelectedObj与returnArray有公共对象，进行过滤
          this.checkboxSelectedObj.push(
            ...returnArray.filter(
              (item) =>
                !this.checkboxSelected.includes(item[this.checkboxFiledName])
            )
          );
          returnArray = this.checkboxSelectedObj;
        } else {
          //删除
          returnArray = this.checkboxSelectedObj;
          returnArray = returnArray.filter((item) =>
            this.checkedItem.includes(item[this.checkboxFiledName])
          );
        }
      }
      this.$emit('checkboxChangeEven', this.checkedItem.slice(), returnArray);
    },
    //取出非当前页的数据或当前页选中的数据
    notCurrentDataFunc(isNotCurPage) {
      let selectedArr = []; //checkboxFiledName
      let noSelectedArr = this.checkedItem.reduce((box, item) => {
        if (!this.itemOptions.some((itemTwo) => item == itemTwo)) {
          box.push(item);
        } else {
          selectedArr.push(item);
        }
        return box;
      }, []);
      if (isNotCurPage) {
        return noSelectedArr;
      }
      return selectedArr;
    },
    //选中单个复选触发
    handlecheckedItemChange(value, isPageSpecific) {
      let checkedCount = this.notCurrentDataFunc(false).length;
      this.checkAll =
        checkedCount === this.itemOptions.length && checkedCount > 0;
      if (this.maxSelectedCount) {
        this.checkAll =
          this.maxSelectedCount == this.checkedCount && checkedCount > 0;
      }
      if (!isPageSpecific) {
        let returnArray = [];
        if (this.isReturnObj) {
          returnArray = this.getIdObj();
          //旧的复选数组this.checkboxSelected小于当前this.checkedItem数组长度，表示添加数据
          if (this.checkboxSelected.length < this.checkedItem.length) {
            //添加，防止分页添加造成this.checkboxSelectedObj与returnArray有公共对象，进行过滤
            this.checkboxSelectedObj.push(
              ...returnArray.filter(
                (item) =>
                  !this.checkboxSelected.includes(item[this.checkboxFiledName])
              )
            );
            returnArray = this.checkboxSelectedObj;
          } else {
            //删除
            returnArray = this.checkboxSelectedObj.filter((item) =>
              this.checkedItem.includes(item[this.checkboxFiledName])
            );
            // returnArray = this.checkboxSelectedObj;
          }
        }
        this.$emit('checkboxChangeEven', this.checkedItem.slice(), returnArray);
      }
    },
    //实现隔行换色的效果
    tableRowClassName({ row, column, rowIndex }) {
      return rowIndex % 2 != 0 ? 'xm-table-interlacing-bg' : '';
    },
    //触发导出
    exportDetails() {
      this.$emit('export');
    },
    //获取选中id的Obj
    getIdObj() {
      return this.table.filter((item) =>
        this.checkedItem.some(
          (itemTwo) => itemTwo == item[this.checkboxFiledName]
        )
      );
    },
    // 如果不允许取消选中初始选中的选项
    // 则根据id确定某个选项是否 disabled
    isItemDisabled(id) {
      let isDisabled =
        !this.isCancelCheckedAllowed && this.originalCheckedSet.has(id);
      return isDisabled;
    },
  },
  watch: {
    checkboxSelected(newSelectedArray) {
      if (!newSelectedArray.length) {
        this.checkAll = false;
      }
      this.checkedItem = newSelectedArray.slice();
      if (this.notCurrentDataFunc(false).length == this.itemOptions.length) {
        this.checkAll = true;
      }
    },
    table: {
      handler(value) {
        this.handlecheckedItemChange(this.checkedItem, true);
      },
      deep: true,
    },
  },
  mounted() {
    this.browserVersion(); // 检测浏览器版本
    this.$nextTick(() => {
      // 如果不允许取消初始选中的选项
      if (!this.isCancelCheckedAllowed) {
        // 保存初始已选中的数组
        this.originalCheckedSet = new Set(this.checkboxSelected);
      }
    });
  },
};
</script>
<style>
/* 头部背景颜色 */
.xm-table-header-bg {
  background-color: #f5f5f5 !important;
  box-shadow: inset 0px -1px 0px 0px #ebeef5;
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
}
/* 隔行换色颜色 */
.xm-table-interlacing-bg {
  background-color: var(--xm-table-bg) !important;
}
</style>
<style lang="scss" scoped>
@use '@/styles/mixin.scss' as commonStyle;
.tableStyle {
  // 公用的滚动条美化样式
  @include commonStyle.pretty-scrollbar;

  &.adapt-low-version {
    ::v-deep .el-table .el-table__body .el-table__cell {
      padding: 13px 0 !important;
    }
  }
}

::v-deep .el-table__cell.gutter {
  background: #f5f5f5;
}
.isFlex {
  display: flex;
}
/* 全选范围样式 */
.allCheckbox {
  background: #f1f2f4;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  border-bottom: 1px solid #dce4f4;
}
.overflowHide {
  overflow-x: overlay;
}
.tableStyle ::v-deep .el-checkbox__inner {
  border-color: #808080 !important;
}
.tableStyle ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: transparent !important;
}
.tableStyle ::v-deep .el-table td.el-table__cell,
.tableStyle ::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0;
}
.tableStyle ::v-deep .el-table::before {
  background-color: white;
}
.allCheckbox ::v-deep .el-checkbox__label {
  color: #666666;
  font-size: 14px;
}
/* 表格头部样式 */
.tableStyle ::v-deep .el-table .cell {
  padding-left: 15px;
  padding-right: 0;
  color: #333333;
  font-size: 14px;
}
/* 全选情况下第一个表头项margin-left:20px; */
.marginLeft28 {
  margin-left: 28px !important;
}
/* 导出部分样式 */
.derive,
.derive-item {
  display: flex;
  color: #333333;
  font-size: 14px;
  align-items: center;
  margin: 20px 0;
}
.derive-label span {
  color: #666666;
}
.derive-label {
  flex-shrink: 0;
}
.derive-btn {
  display: inline-block;
  width: 88px;
  height: 40px;
  border: 1px solid #cccccc;
  color: #808080;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;

  cursor: pointer;
}
/* 有横项滚动条时去除最后一行的下边框 */
.isLevelScrollBar
  .el-table
  ::v-deep
  .el-table__body
  .el-table__row:last-child
  .el-table__cell {
  border-bottom: 0;
}
/* 为每行底部增加下边框 */
.isBottomBorder
  .el-table
  ::v-deep
  .el-table__body
  .el-table__row
  .el-table__cell {
  border-bottom: 1px solid var(--bottomBorderColor);
}
.el-table ::v-deep th.el-table__cell {
  height: 40px;
  line-height: 40px;
  padding: 0;
}
.el-table ::v-deep th.el-table__cell .cell {
  padding: 0;
  padding-left: 15px;
  color: #666;
}
.el-table ::v-deep .has-gutter th:first-child .cell {
  padding-left: 15px;
}
// 显示更多字段位置
.moreFieldList-box {
  margin-left: auto;
}
// 一行溢出隐藏
.one-line {
  display: block;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// 两行溢出隐藏
.two-line {
  width: fit-content;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>

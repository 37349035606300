<template>
  <div class="input-model">
    <el-input
      :disabled="disabled"
      :value="value"
      @input="inputChange($event)"
      @change="handleChange"
      :placeholder="placeholder"
    >
      <span slot="suffix" class="searchicon" v-if="showUnit"> {{ type }} </span>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Number,
    type: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    // 是否展示单位
    showUnit: {
      type: Boolean,
      default: true,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    inputChange(val) {
      switch (this.type) {
        case '件':
          val = val.replace(/[^\d]/g, '');
          break;
        case '天':
        case '小时':
        case '$':
        case '¥':
        case '￥':
        case '＄':
        case '€':
        case '￡':
        case 'A$':
        case 'kg':
        case '元':
        case '美元':
        case 'm²':
          val = val.replace(/[^\d.]/g, ''); // 只能输入数字和.
          val = val.replace(/^\./g, ''); //第一个字符不能是.
          val = val.replace(/\.{2,}/g, '.'); // 不能连续输入.
          val = val.replace(/(\.\d+)\./g, '$1'); // .后面不能再输入.
          val = val.replace(/^0+(\d)/, '$1'); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          val = val.replace(/(\d{15})\d*/, '$1'); // 最多保留15位整数
          val = val.replace(/(\.\d{2})\d*/, '$1'); // 最多保留2位小数
          break;
        case '%':
          val = val.replace(/[^\d.]/g, ''); // 只能输入数字和.
          val = val.replace(/^\./g, ''); //第一个字符不能是.
          val = val.replace(/\.{2,}/g, '.'); // 不能连续输入.
          val = val.replace(/(\.\d+)\./g, '$1'); // .后面不能再输入.
          val = val.replace(/^0+(\d)/, '$1'); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
          val = val.replace(/(\d{3})\d*/, '$1'); // 最多保留3位整数
          val = val.replace(/(\.\d{2})\d*/, '$1'); // 最多保留2位小数
          break;
        case '人':
          val = val.replace(/[^\d]/g, '');
          val = Number(val) > 0 ? val : '';
          break;
      }
      this.$emit('input', val);
    },
    // 仅在输入框失去焦点或用户按下回车时触发
    handleChange(val) {
      this.$emit('change', val);
    },
  },
};
</script>
<style scoped lang="scss">
.input-model {
  .searchicon {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #808080;
  }

  &::v-deep .el-input__suffix {
    position: absolute;
    height: 100%;
    right: 0px;
    top: 50%;
    color: #c0c4cc;
    transition: all 0.3s;
    pointer-events: none;
    width: fit-content;
    transform: translateY(-50%);
    line-height: 40px;
    padding-right: 16px;
  }
}
</style>

<template>
  <div class="box-container">
    <el-select
      v-model="completeAddress"
      multiple
      clearable
      popper-class="xm-check-area"
      slot="reference"
      @visible-change="returnAreaFunc"
      @change="changeFunc"
    >
      <template slot="empty">
        <div style="width: 522px">
          <div class="search" v-if="false">
            <el-input v-model="search_value" placeholder="搜索国家">
              <img class="search-icon" slot="prefix" :src="searchIcon" />
            </el-input>
            <el-button type="primary" size="mini">搜索</el-button>
          </div>
          <div class="main1">
            <div class="column country-class">
              <h2 class="column_title">
                <span>国家</span>
              </h2>
              <div class="column_body">
                <el-input
                  class="input_style"
                  v-model="search_Country"
                  placeholder="请输入国家"
                  size="mini"
                  @input="searchCountry"
                  ><img
                    class="search-icon"
                    width="16px"
                    height="16px"
                    slot="prefix"
                    :src="searchIcon"
                  />
                </el-input>
                <div v-for="group in currentCountryList" :key="group.initial">
                  <div class="country-initial">
                    <span
                      :class="{
                        active:
                          initialActive.toUpperCase() ==
                          group.initial.toUpperCase(),
                      }"
                    >
                      {{ group.initial }}
                    </span>
                    <hr class="country-group-divider" />
                  </div>
                  <ul class="area_list">
                    <li
                      class="area_item"
                      v-for="item in group.list"
                      :key="item.id"
                    >
                      <el-checkbox
                        :indeterminate="item.indeterminate"
                        v-model="item.checkbox"
                        @change="selected1($event, item)"
                      >
                      </el-checkbox>
                      <div
                        class="text-style"
                        :class="{ 'icon-active': item.id == current_country }"
                        @click="setCurrentCountry(item)"
                        :title="item.cname ? item.cname : item.name"
                      >
                        {{ item.cname ? item.cname : item.name }}
                      </div>
                      <div class="icon-style" @click="setCurrentCountry(item)">
                        <i
                          class="el-icon-arrow-right"
                          :class="{ 'icon-active': item.id == current_country }"
                          v-if="typeShow != 'country'"
                        ></i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column">
              <h2 class="column_title">
                <span>省份</span>
              </h2>
              <div class="column_body province-class">
                <ul class="area_list">
                  <li
                    class="area_item"
                    v-for="item in currentProvinceList"
                    :key="item.id"
                  >
                    <el-checkbox
                      :indeterminate="item.indeterminate"
                      v-model="item.checkbox"
                      @change="selected2($event, item)"
                    >
                    </el-checkbox>
                    <div
                      class="text-style"
                      :class="{ 'icon-active': item.id == current_province }"
                      @click="setCurrentProvince(item)"
                      :title="item.cname ? item.cname : item.name"
                    >
                      {{ item.cname ? item.cname : item.name }}
                    </div>
                    <div class="icon-style" @click="setCurrentProvince(item)">
                      <i
                        class="el-icon-arrow-right"
                        :class="{ 'icon-active': item.id == current_province }"
                      ></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="column city-container">
              <h2 class="column_title">
                <span>城市</span>
              </h2>
              <div class="column_body city-class">
                <ul class="area_list">
                  <li
                    class="area_item area_item-last"
                    v-for="item in currentCityList"
                    :key="item.id"
                  >
                    <el-checkbox
                      :indeterminate="item.indeterminate"
                      v-model="item.checkbox"
                      @change="selected3($event, item)"
                    >
                    </el-checkbox>
                    <div
                      class="text-style"
                      :title="item.cname ? item.cname : item.name"
                    >
                      {{ item.cname ? item.cname : item.name }}
                    </div>
                    <div v-if="false">
                      <i
                        class="icon"
                        :class="{ 'icon-active': item.id == current_city }"
                      ></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="result" v-if="Category != 'china'">
            <span class="label">已选择国家：</span>
            <p class="label_area">
              <el-tag
                type="warning"
                v-for="country in select_country_numberList"
                closable
                :key="'selected' + country.id"
                @close="selected1(false, country)"
              >
                {{ country.cname ? country.cname : country.name }}
              </el-tag>
            </p>
          </div>
          <div class="result" v-if="typeShow != 'country'">
            <span class="label">已选择省：</span>
            <p class="label_area">
              <el-tag
                type="warning"
                v-for="province in select_province_numberList"
                closable
                :key="'selected' + province.id"
                @close="selected2(false, province)"
              >
                {{ province.cname ? province.cname : province.name }}
              </el-tag>
            </p>
          </div>
          <div class="result" v-if="typeShow != 'country'">
            <span class="label">已选择市：</span>
            <p class="label_area">
              <el-tag
                type="warning"
                v-for="city in select_city_numberList"
                closable
                :key="'selected' + city.id"
                @close="selected3(false, city)"
              >
                {{ city.cname ? city.cname : city.name }}
              </el-tag>
            </p>
          </div>
        </div>
      </template>
    </el-select>
  </div>
</template>
<script>
import {
  getCountries,
  getStates,
  getCity,
} from '@/utils/api/address/addressApi.js';

const TAG_MAPPING = {
  continent: 'success',
  country: '',
  province: 'warning',
  city: 'danger',
};
export default {
  name: 'AreaCheck',
  data() {
    return {
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      completeAddress: [],
      area: [], //大州列表
      TAG_MAPPING,
      isCheckbox: true,
      search_value: '',
      search_Country: '', //国家搜索
      search_city: '', //城市搜索
      current_continent: '', //当前的大洲
      currentCountryList: [], //当前的国家列表
      current_country: '', //当前的国家
      current_country1: {}, //国
      currentProvinceList: [], //当前的省份列表
      current_province: '', //当前的省份
      current_province1: {}, //省
      currentCityList: [], //当前的城市列表
      current_city: '', //当前的城市
      current_city1: {}, //市
      select_continent_number: 0, //选中的大洲数量
      select_country_number: 0, //选中的国家数量
      select_country_numberList: [], //选中国家进入的列表
      select_country_code: [], //选中的国家code
      select_province_number: 0, //选中的省份数量
      select_province_numberList: [], //选中省/州进入列表
      select_province_code: [], //选中的省/州code
      select_city_number: 0, //选中的城市数量
      select_city_numberList: [], //选中的市进入列表
      select_city_code: [], //选中的市code
      select: [], //选中的国家地区列表
      select_code: [],
      resultWidth: '200px', //选择的宽度
      typeShow: '',
      initialActive: '', //记录搜索字母高亮
    };
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    select_list: {
      type: Array,
      default: () => [],
    },
    Category: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      defualt: '522px',
    },
  },
  watch: {
    select_list: {
      handler(newValue) {
        // if (newValue.length) {
        this.getselectList();
        this.returnAreaFunc(false);
        this.getCountries1({ is_initial_group: 1 });
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {
    this.getCountries1({ is_initial_group: 1 });
    this.typeShow = this.type;
  },
  methods: {
    //编辑专用(解决引用问题国家)
    showCountryFunc(country_id) {
      this.currentCountryList.forEach((itemTwo) => {
        itemTwo.list.some((itemThree) => {
          if (itemThree.id == country_id) {
            itemThree.checkbox = false;
            return true;
          }
        });
      });
    },
    //删除input中的标签触发
    changeFunc(newValue) {
      let selectedCountry = JSON.parse(
        JSON.stringify(this.select_country_numberList)
      );
      let newCountry = selectedCountry.reduce((box, item) => {
        let flag = newValue.some((itemTwo) => {
          if (itemTwo == item.cname) {
            box.push(item);
            return true;
          }
        });

        if (!flag) {
          this.selected1(false, item);
        }
        return box;
      }, []);

      this.select_country_numberList = newCountry;
      let selectedProvice = JSON.parse(
        JSON.stringify(this.select_province_numberList)
      );

      let newProvice = selectedProvice.reduce((box, item) => {
        let flag = newValue.some((itemTwo) => {
          if (itemTwo == item.cname) {
            box.push(item);
            return true;
          }
        });
        if (!flag) {
          this.selected2(false, item);
        }
        return box;
      }, []);

      this.select_province_numberList = newProvice;
      let selectedCity = JSON.parse(
        JSON.stringify(this.select_city_numberList)
      );
      let newCity = selectedCity.reduce((box, item) => {
        let flag = newValue.some((itemTwo) => {
          if (itemTwo == item.cname) {
            box.push(item);
            return true;
          }
        });
        if (!flag) {
          this.selected3(false, item);
        }
        return box;
      }, []);

      this.select_city_numberList = newCity;
      this.returnAreaFunc(false);
    },
    //回显专用
    getselectList() {
      this.select = JSON.parse(JSON.stringify(this.select_list));
      this.select_country_numberList = [];
      this.select_province_numberList = [];
      this.select_city_numberList = [];
      this.select.forEach((item) => {
        switch (item.type) {
          case 'countrys':
            this.select_country_numberList.push(item);
            break;
          case 'states':
            this.select_province_numberList.push(item);
            break;
          case 'citys':
            this.select_city_numberList.push(item);
            break;
          default:
            break;
        }
      });
      this.getAddressInfo();
    },
    // 获取国家列表
    async getCountries1(options, isSearch) {
      try {
        options.MUTE_WARNING = 1;
        let res = await getCountries(options);
        if (res.code === 200) {
          if (isSearch && this.search_Country && res.data.length) {
            this.initialActive = res.data[0].initial;
          }
          res.data.forEach((item) => {
            item.list.forEach((itemTwo) => {
              itemTwo.checkbox = false;
              itemTwo.type = 'countrys';
            });
          });
          // 根据国家选中列表回显

          if (this.select_country_numberList.length > 0) {
            res.data.forEach((item) => {
              item.list.forEach((itemTwo) => {
                this.select_country_numberList.forEach((itemThree) => {
                  if (itemTwo.code == itemThree.code) {
                    itemTwo.checkbox = true;
                  }
                });
              });
            });
          }
          this.currentCountryList = res.data;
          if (this.currentCountryList.length > 0) {
            this.resultWidth = '400px';
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    //复选国家
    selected1(isCheckbox, currentCountryList) {
      console.log('------', currentCountryList);
      currentCountryList.checkbox = isCheckbox;
      this.currentCountryList.forEach((item) => {
        item.list.forEach((itemTwo) => {
          if (itemTwo.code == currentCountryList.code) {
            itemTwo.checkbox = isCheckbox;
          }
        });
      });
      //如果为true 进选中国家列表中
      if (currentCountryList.checkbox) {
        this.select_country_numberList.push(currentCountryList);
        this.select_country_code.push(currentCountryList.code);
        if (currentCountryList.has_state == '1') {
          console.log(this.select_province_numberList, currentCountryList);
        }
      }
      if (!currentCountryList.checkbox) {
        let idx = this.select_country_numberList.findIndex(
          (index) => index.code == currentCountryList.code
        );
        if (idx >= 0) {
          this.select_country_numberList.splice(idx, 1);
          this.select_country_code.splice(idx, 1);
        }
      }
      this.getAddressInfo();
      //根据选中的国家，将子元素点亮(1-有省,0-没省)
      if (currentCountryList.has_state == '1') {
        this.currentProvinceList.forEach((item) => {
          if (item.country_id == currentCountryList.id) {
            if (!currentCountryList.checkbox) {
              item.checkbox = false;
            }
          }
        });
        // 当前城市列表不是当前国的情况
        let findArr = this.select_province_numberList.filter(
          (item) =>
            item.country_id == currentCountryList.id &&
            currentCountryList.checkbox === true
        );
        for (let i = 0; i < findArr.length; i++) {
          let idx = this.select_province_numberList.findIndex(
            (item) =>
              currentCountryList.checkbox == true &&
              item.country_id == currentCountryList.id
          );
          if (idx >= 0) {
            this.select_province_numberList.splice(idx, 1);
            this.select_province_code.splice(idx, 1);
          }
        }
        this.select_country_numberList.forEach((item) => {
          this.currentProvinceList.forEach((item2) => {
            if (item.id == item2.country_id) {
              if (item.checkbox == true) {
                item2.checkbox = true;
                let idx = this.select_province_numberList.findIndex(
                  (index) => index.checkbox == true
                );
                if (idx >= 0) {
                  this.select_province_numberList.splice(idx, 1);
                  this.select_province_code.splice(idx, 1);
                }
              }
            }
          });
        });
      } else if (currentCountryList.has_state == '0') {
        this.currentCityList.forEach((item) => {
          if (item.country_id == currentCountryList.id) {
            if (!currentCountryList.checkbox) {
              item.checkbox = false;
            }
          }
        });
        // 当前城市列表不是当前国的情况
        let findArr = this.select_city_numberList.filter(
          (item) =>
            item.country_id == currentCountryList.id &&
            currentCountryList.checkbox === true
        );
        for (let i = 0; i < findArr.length; i++) {
          let idx = this.select_city_numberList.findIndex(
            (item) =>
              currentCountryList.checkbox === true &&
              item.country_id == currentCountryList.id
          );
          if (idx >= 0) {
            this.select_city_numberList.splice(idx, 1);
            this.select_city_code.splice(idx, 1);
          }
        }
        // 当前城市列表是当前国的情况
        this.select_country_numberList.forEach((item) => {
          this.currentCityList.forEach((item2) => {
            if (item.id == item2.country_id) {
              if (item.checkbox == true) {
                item2.checkbox = true;
                let idx = this.select_city_numberList.findIndex(
                  (index) =>
                    index.checkbox == true && index.country_id === item.id
                );
                if (idx >= 0) {
                  this.select_city_numberList.splice(idx, 1);
                  this.select_city_code.splice(idx, 1);
                }
              }
            }
          });
        });
      }
    },
    //获取input展示标签
    getAddressInfo() {
      let one = this.select_country_numberList.map((item) => item.cname);
      let two = this.select_province_numberList.map((item) => item.cname);
      let three = this.select_city_numberList.map((item) => item.cname);
      this.completeAddress = [];
      this.completeAddress = [...one, ...two, ...three];
    },
    //箭头选中国家
    setCurrentCountry(country) {
      (this.currentCityList = []), //城市列表
        (this.current_province = '');
      this.current_city = '';
      //设置当前的国家
      this.current_country = country.id;
      this.current_country1 = country;
      this.search_value = '';
      this.search_city = '';
      let options = {
        id: this.current_country,
      };
      //没省
      if (country.has_state == '0') {
        (this.currentProvinceList = []), //省列表
          (options.type = 'country'),
          //市
          this.getCity(options, false);
      } else if (country.has_state == '1') {
        // 有省
        //省
        this.getStates1(options);
      }
    },
    // 搜索省
    setCurrentCountry1() {
      let options = {
        id: this.current_country,
        name: this.search_value,
      };
      this.getStates1(options);
    },
    // 获取省列表
    async getStates1(options) {
      try {
        options.MUTE_WARNING = 1;
        let res = await getStates(options);
        if (res.code == 200) {
          res.data.forEach((item) => {
            item.checkbox = false;
            item.type = 'states';
            item.continents_id = this.current_continent;
          });
          if (this.select_province_numberList.length > 0) {
            res.data.forEach((item) => {
              this.select_province_numberList.forEach((item2) => {
                if (item.code_full == item2.code_full) {
                  item.checkbox = true;
                }
              });
            });
          }
          let arr = this.currentCountryList.reduce((box, item) => {
            if (res.data && res.data.length > 0) {
              item.list.forEach((itemTwo) => {
                if (itemTwo.id == res.data[0].country_id) {
                  box.push(itemTwo);
                }
              });
            }
            return box;
          }, []);
          console.log(arr, 'arr');
          res.data.forEach((item) => {
            if (arr.length && arr[0].checkbox == true) {
              item.checkbox = true;
              this.getAddressInfo();
            }
          });
          this.currentProvinceList = res.data;
          if (this.currentProvinceList.length > 0) {
            this.resultWidth = '600px';
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    //复选省
    selected2(isCheckbox, currentProvinceList) {
      currentProvinceList.checkbox = isCheckbox;
      this.currentProvinceList.forEach((item) => {
        if (item.code_full == currentProvinceList.code_full) {
          item.checkbox = isCheckbox;
        }
      });
      //如果为true 进选中省/州列表中
      if (currentProvinceList.checkbox) {
        this.select_province_numberList.push(currentProvinceList);
        this.select_province_code.push(currentProvinceList.code_full);
      } else if (!currentProvinceList.checkbox) {
        let idx = this.select_province_numberList.findIndex(
          (index) => index.code_full == currentProvinceList.code_full
        );
        if (idx >= 0) {
          this.select_province_numberList.splice(idx, 1);
          this.select_province_code.splice(idx, 1);
        }
      }
      //根据选中的省，将子元素点亮 并且移出市列表
      this.currentCityList.forEach((item) => {
        if (item.state_id == currentProvinceList.id) {
          if (!currentProvinceList.checkbox) {
            item.checkbox = false;
          }
        }
      });
      // 当前城市列表不是当前国的情况
      let findArr = this.select_city_numberList.filter(
        (item) =>
          item.state_id === currentProvinceList.id &&
          currentProvinceList.checkbox === true
      );
      for (let i = 0; i < findArr.length; i++) {
        let idx = this.select_city_numberList.findIndex(
          (item) =>
            currentProvinceList.checkbox === true &&
            item.state_id === currentProvinceList.id
        );
        if (idx >= 0) {
          this.select_city_numberList.splice(idx, 1);
          this.select_city_code.splice(idx, 1);
        }
      }
      // 当前城市列表是当前省的情况
      this.select_province_numberList.forEach((item) => {
        this.currentCityList.forEach((item2) => {
          if (item.id == item2.state_id) {
            if (item.checkbox == true) {
              item2.checkbox = true;
              let idx = this.select_city_numberList.findIndex(
                (index) => index.checkbox == true && index.state_id === item.id
              );
              if (idx >= 0) {
                this.select_city_numberList.splice(idx, 1);
                this.select_city_code.splice(idx, 1);
              }
            }
          }
        });
      });
      //根据子元素对父元素进行操作
      if (!currentProvinceList.checkbox) {
        this.select_country_numberList.forEach((item) => {
          if (item.id == currentProvinceList.country_id) {
            item.checkbox = false;
            let idx = this.select_country_numberList.findIndex(
              (index) => index.checkbox == false
            );
            if (this.select_list.length) {
              this.showCountryFunc(currentProvinceList.country_id);
            }
            if (idx >= 0) {
              this.select_country_numberList.splice(idx, 1);
              this.select_country_code.splice(idx, 1);
            }
            this.currentProvinceList.forEach((item) => {
              if (item.checkbox == true) {
                this.select_province_numberList.push(item);
                this.select_province_code.push(item.code_full);
              }
            });
          }
        });
      } else {
        // 子元素全亮父元素也亮
        let arr = this.select_province_numberList.filter(
          (item) => item.country_id == this.current_country1.id
        );
        if (arr.length == this.currentProvinceList.length) {
          let arr1 = this.currentCountryList.reduce((box, item) => {
            item.list.forEach((itemTwo) => {
              if (itemTwo.id == arr[0].country_id) {
                box.push(itemTwo);
              }
            });
            return box;
          }, []);
          this.currentCountryList.forEach((item) => {
            item.list.forEach((itemTwo) => {
              if (itemTwo.id == arr1[0].id) {
                itemTwo.checkbox = true;
                this.selected1(itemTwo.checkbox, arr1[0]);
              }
            });
          });
        }
      }
      this.getAddressInfo();
    },

    //箭头选中省
    setCurrentProvince(states) {
      // this.currentCityList = [],//城市列表
      this.current_city = '';
      this.current_province = states.id;
      this.current_province1 = states;
      this.search_city = '';
      const options = {
        id: this.current_province,
      };
      this.getCity(options, true);
    },

    // 获取城市列表
    async getCity(options, isProvince) {
      try {
        options.type = isProvince ? 'province' : 'country';
        options.MUTE_WARNING = 1;
        // if (!isProvince) {
        //   options.type = 'country';
        // }
        let res = await getCity(options);
        if (res.code == 200) {
          res.data.forEach((item) => {
            item.type = 'citys';
            item.continents_id = this.current_continent;
            if (options.type == 'country') {
              item.has_state = '1';
              item.country_id = this.current_country;
            } else {
              item.has_state = '0';
            }
          });
          if (this.select_city_numberList.length > 0) {
            res.data.forEach((item) => {
              this.select_city_numberList.forEach((item2) => {
                if (item.code_full == item2.code_full) {
                  item.checkbox = true;
                }
              });
            });
          }
          if (options.type === 'country') {
            let arr = this.currentCountryList.reduce((box, item) => {
              if (res.data && res.data.length > 0) {
                item.list.forEach((itemTwo) => {
                  if (itemTwo.id == res.data[0].country_id) {
                    box.push(itemTwo);
                  }
                });
              }
              return box;
            }, []);
            res.data.forEach((item) => {
              if (arr.length > 0 && arr[0].checkbox == true) {
                item.checkbox = true;
              }
            });
          } else if (options.type === 'province') {
            let arr = this.currentProvinceList.filter((item) => {
              if (res.data && res.data.length > 0) {
                return item.id == res.data[0].state_id;
              }
            });
            res.data.forEach((item) => {
              if (arr.length > 0 && arr[0].checkbox == true) {
                item.checkbox = true;
              }
            });
          }

          this.currentCityList = res.data;
          if (this.currentCityList.length > 0) {
            this.resultWidth = '600px';
          }
        }
      } catch (err) {
        console.error(err);
      }
    },

    // 复选市
    selected3(isCheckbox, currentCityList) {
      currentCityList.checkbox = isCheckbox;
      this.currentCityList.forEach((item) => {
        if (item.code_full == currentCityList.code_full) {
          item.checkbox = isCheckbox;
        }
      });
      //如果为true 进选中省/州列表中
      if (currentCityList.checkbox) {
        this.select_city_numberList.push(currentCityList);
        this.select_city_code.push(currentCityList.code_full);
      } else if (!currentCityList.checkbox) {
        let idx = this.select_city_numberList.findIndex(
          (index) => index.code_full == currentCityList.code_full
        );
        if (idx >= 0) {
          this.select_city_numberList.splice(idx, 1);
          this.select_city_code.splice(idx, 1);
        }
      }
      //根据子元素对父元素进行操作 (1-有省 0-没省)
      if (this.current_country1.has_state == '1') {
        if (!currentCityList.checkbox) {
          if (this.current_country1.checkbox == false) {
            this.select_province_numberList.forEach((item) => {
              if (item.id == currentCityList.state_id) {
                item.checkbox = false;
                let idx = this.select_province_numberList.findIndex(
                  (index) => index.checkbox == false
                );
                if (this.select_list.length) {
                  this.currentProvinceList.some((itemTwo) => {
                    if (itemTwo.id == currentCityList.state_id) {
                      itemTwo.checkbox = false;
                      return true;
                    }
                  });
                }
                if (idx >= 0) {
                  this.select_province_numberList.splice(idx, 1);
                  this.select_province_code.splice(idx, 1);
                }
                this.currentCityList.forEach((item) => {
                  if (item.checkbox == true) {
                    this.select_city_numberList.push(item);
                    this.select_city_code.push(item.code_full);
                  }
                });
              }
            });
          } else {
            let arr = this.currentProvinceList.filter(
              (item) => item.id == currentCityList.state_id
            );
            this.selected2(currentCityList.checkbox, arr[0]);
          }
        } else {
          // 子元素全亮父元素也亮
          let arr = this.select_city_numberList.filter(
            (item) => item.state_id == this.current_province1.id
          );
          if (arr.length == this.currentCityList.length) {
            let arr1 = this.currentProvinceList.filter(
              (item) => item.id == arr[0].state_id
            );
            this.currentProvinceList.forEach((item) => {
              if (item.id == arr1[0].id) {
                item.checkbox = true;
                this.selected2(item.checkbox, arr1[0]);
              }
            });
          }
        }
      } else if (this.current_country1.has_state == '0') {
        if (!currentCityList.checkbox) {
          this.select_country_numberList.forEach((item) => {
            if (item.id == currentCityList.country_id) {
              item.checkbox = false;
              let idx = this.select_country_numberList.findIndex(
                (index) => index.checkbox == false
              );
              if (this.select_list.length) {
                this.showCountryFunc(currentCityList.country_id);
              }
              if (idx >= 0) {
                this.select_country_numberList.splice(idx, 1);
                this.select_country_code.splice(idx, 1);
              }

              this.currentCityList.forEach((item) => {
                if (item.checkbox == true) {
                  this.select_city_numberList.push(item);
                  this.select_city_code.push(item.code_full);
                }
              });
            }
          });
        } else {
          // 过滤出选中的城市列表 子元素全亮父元素也亮
          let arr = this.select_city_numberList.filter(
            (item) => item.country_id == this.current_country1.id
          );
          if (arr.length == this.currentCityList.length) {
            let arr1 = this.currentCountryList.reduce((box, item) => {
              item.list.forEach((itemTwo) => {
                if (itemTwo.id == arr[0].country_id) {
                  box.push(itemTwo);
                }
              });
              return box;
            }, []);
            this.currentCountryList.forEach((item) => {
              item.list.forEach((itemTwo) => {
                if (itemTwo.id == arr1[0].id) {
                  itemTwo.checkbox = true;
                  this.selected1(itemTwo.checkbox, arr1[0]);
                }
              });
            });
          }
        }
      }
      this.getAddressInfo();
    },
    //搜索
    searchCountry() {
      const options = {
        is_initial_group: 1,
        name: this.search_Country,
      };
      if (this.Category == 'international') {
        options.type_rote = 'international';
      }
      this.getCountries1(options, true);
    },
    //地区确认按钮
    returnAreaFunc(flag) {
      if (!flag) {
        this.select = [];
        this.select = [
          ...this.select_country_numberList,
          ...this.select_province_numberList,
          ...this.select_city_numberList,
        ];
        this.select_code = [
          ...this.select_country_code,
          ...this.select_province_code,
          ...this.select_city_code,
        ];
        this.showing = !this.showing;
        this.$emit(
          'confirm',
          JSON.parse(JSON.stringify(this.select)),
          this.select_code
        );
        // this.$emit('configAreaBtn', this.select);
      }
    },
  },
};
</script>
<style lang="scss">
.xm-check-area {
  .input_style {
    padding: 10px;
    padding-bottom: 0;
    .el-input__inner {
      width: 154px;
      height: 32px;
      padding-left: 25px;
      background-color: #f7f7f7;
    }
  }
  .el-input__prefix {
    top: 10px;
    height: 36px;
    line-height: 36px;
    left: 17px;
  }
  .popper__arrow {
    display: none;
  }
  ::v-deep .city-container {
    .area_item {
      padding-right: 10px;
    }
  }
  // 国家板块拼音首字母选项部分
  .country-initial {
    margin-top: 8px;
    display: flex;
    padding: 0 10px;
    & > span {
      color: #666;
      margin-right: 10px;
    }
    & > span.active {
      color: #026bff;
    }
    // 国家按拼音分组，分组的水平分割线
    .country-group-divider {
      width: 132px;
      height: 1px;
      margin: auto;

      border: none;
      border-top: 1px solid #e6e6e6;
    }
  }
  .country-class {
    .area_list .area_item > :first-child {
      padding-left: 20px;
    }
  }
  .province-class,
  .city-class {
    padding-top: 10px;
  }
}
</style>
<style scoped lang="scss">
.box-container {
  .el-select {
    height: 40px;
    overflow: hidden;
  }
  ::v-deep .el-select__tags {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
.area_list,
.main1 .column .column_title {
  font-size: 12px;
  font-weight: 400;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
}

.area_list {
  font-size: 12px;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
  font-weight: 300;
  color: #111111;
}
.city-container .area_list .area_item {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-style {
  margin-left: 5px;
  flex: auto;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-active {
  color: #026bff;
}
.justify-start {
  justify-content: flex-start;
}

.area_list {
  line-height: 26px;
}

.area_list .area_item {
  border-radius: 4px;
  display: flex;
  /* padding: 2px 0; */
  height: 22px;
  line-height: 22px;
  margin-top: 10px;
}

.area_list .area_item > :first-child {
  padding-left: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
  font-weight: 300;
  color: #111111;
}
.icon-style {
  margin-left: auto;
}
.area_list .area_item > :last-child {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.city-container .area_item > :last-child {
  display: block;
}
.area_list .area_item:hover {
  /* background-color: rgba(255, 120, 2, 0.1); */
  color: #026bff !important;
  cursor: pointer;
}
.area_item-last >>> .el-checkbox__label {
  font-size: 14px;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
  font-weight: 300;
  color: #111111;
}
.area_list .area_item.area_item-active {
  background: #eff5ff;
  border-radius: 4px;
  opacity: 1;
  border: 1px solid #3271ff;
}

.area_list .area_item-last > :last-child {
  justify-content: flex-start;
}

.main1 {
  border-radius: 4px;
  border: 1px solid #eee;
  display: flex;
}

.main1 .column {
  flex-shrink: 0;
}

.main1 .column .column_title {
  height: 36px;
  /* background: rgba(255, 120, 2, 0.1); */

  background: #f2f2f2;
  color: #666;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 10px;
}

.main1 .column .column_title > :first-child {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
}

.main1 .column .column_body {
  height: 304px;
  width: 174px;
  border-right: 1px solid #eee;
  overflow-y: auto;
  /* padding: 20px */
}

.main1 .column .column_body::-webkit-scrollbar {
  display: none;
}

.main1 .column:last-child .column_body {
  border-right: none;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.del_style {
  width: 68px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #cccccc;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #808080;
  margin-right: 20px;
  cursor: pointer;
}
.cofig_style {
  width: 68px;
  height: 40px;
  background: #026bff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
}

.result {
  min-height: 24px;
  /* background-color: rgba(255,120,2,0.1); */
  margin: 10px;
  display: flex;
  width: var(--width1);
  flex-flow: row nowrap;
  border-radius: 4px;
  line-height: 24px;
}

.result .label {
  white-space: nowrap;
  font-size: 12px;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
  font-weight: 300;
  color: #4d4d4d;
  height: 24px;
}

.result .label_area span {
  padding: 0 0.5em;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: 1.5;
  white-space: nowrap;
  margin: 0 0.5em 0.5em;
  user-select: none;
}

.label_area {
  max-height: 90px;
  overflow-y: auto;
}

.search {
  margin-bottom: 12px;
  padding: 10px;
}

.el-tag {
  height: auto;
}

.el-dialog {
  min-width: 1000px;
  border-radius: 8px;
}

.el-dialog .el-dialog__header {
  padding: 20px 30px 12px;
}

.el-dialog .el-dialog__header .el-dialog__title {
  font-size: 16px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: 700;
  color: #333;
  line-height: 26px;
}

.el-dialog .el-dialog__body {
  padding: 0 30px;
}

.el-dialog .el-input {
  width: 300px;
  height: 28px;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
}
.cur {
  cursor: pointer;
  color: black;
}
.active {
  color: #026bff !important;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #026bff !important;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
  font-weight: 300;
  color: #111111;
  padding-left: 4px;
}
::v-deep .el-tag.el-tag--warning {
  /* background-color: rgba(255, 120, 2, 0.1); */

  background: #e6f1ff;
  color: #026bff;
}
::v-deep .el-tag.el-tag--warning .el-tag__close:hover {
  background-color: rgba(255, 120, 2, 0.1);
  color: #026bff;
}
::v-deep .el-tag.el-tag--warning .el-tag__close {
  color: #026bff;
}
::v-deep .el-icon-close:hover {
  color: #026bff !important;
}
::v-deep .el-input__inner:focus {
  border: 1px solid #cbcbcb !important;
}
</style>

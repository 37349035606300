import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import chat from './chat'; // 聊天界面状态管理模块
import pageFilter from './page_filter_criteria';//页面条件筛选的记录

Vue.use(Vuex);
export default new Vuex.Store({
  modules: { chat,pageFilter },
  state: {
    currentsku: {},
    myAuths: localStorage.getItem('myAuths') || '[]', // 所有权限
    myBtnPerms: new Set(), // 按钮权限
    updateAuthsFlag: '', // 是否更新权限的哨兵变量
    menuId_level_two: '',
    categoryData: [], // 类目数据
  },
  getters: {
    updateAuths: (state) => !state.updateAuthsFlag,
    myAuths: (state) => {
      let auths;
      if (typeof state.myAuths === 'string') {
        auths = JSON.parse(state.myAuths);
      } else {
        auths = state.myAuths;
      }
      return auths;
    },
  },
  mutations: {
    STE_SKU: (state, data) => (state.currentsku = data),
    SET_AUTHS(state, data) {
      state.myAuths = data;
      localStorage.setItem('myAuths', JSON.stringify(data));
    },
    SET_UPDATE_AUTHS_FLAG(state, data) {
      state.updateAuthsFlag = data;
    },
    SET_BTN_PERMS(state, data) {
      state.myBtnPerms = new Set(data);
    },
    SET_menuId_level_two(state, data) {
      state.menuId_level_two = data;
    },
    SET_CATEGORY_DATA(state, data) {
      state.categoryData = data;
    },
  },
  actions: {
    setsku(context, sku) {
      context.commit('STE_SKU', sku);
    },
    // 设置权限
    setAuths(context, right) {
      context.commit('SET_AUTHS', right);
    },
    // 设置按钮权限
    setBtnPerms(context, rights) {
      context.commit('SET_BTN_PERMS', rights);
    },
    // 设置是否更新权限数据的哨兵
    // 哨兵值随便设，只要是长度大于 0 的字符串就行
    setUpdateAuthsFlag(context, flag) {
      context.commit('SET_UPDATE_AUTHS_FLAG', flag);
    },
    setCurrentMenuName(context, name) {
      context.commit('SET_menuId_level_two', name);
    },
    setCategoryInfo(context, category) {
      context.commit('SET_CATEGORY_DATA', category);
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        'currentsku',
        'myAuths',
        'myBtnPerms',
        'menuId_level_two',
        'chat',
        'pageFilter'
      ],
    }),
    createPersistedState({
      storage: window.localStorage,
      paths: ['categoryData'],
    }),
  ],
});

<template>
  <div class="configmodel">
    <el-dialog
      title="提示"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :visible.sync="deleteDialogVisible"
      :modal-append-to-body="false"
      width="480px"
      :before-close="closeDeletedig"
    >
      <div>
        <div class="content">
          <div class="imgmodel" v-if="type">
            <img v-if="type == 'red'" src="@/assets/images/redtip.svg" />
            <img v-else-if="type == 'yellow'" src="@/assets/images/yeTip.svg" />
          </div>
          <div class="text">
            {{ content }}
          </div>
        </div>
        <slot></slot>
        <div class="btnmodel">
          <div class="btn sure" @click="configDelete">{{ sureBtnFont }}</div>
          <div class="btn" @click="closeDeletedig">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'XimuDialog',
  props: {
    content: {
      type: String,
      default: '确定要删除吗？',
    },
    deleteDialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'red',
    },
    // 确认按钮文字
    sureBtnFont: {
      type: String,
      default: '确认',
    },
  },
  data() {
    return {};
  },
  methods: {
    closeDeletedig() {
      // this.deleteDialogVisible = false;
      this.$emit('closeDeletedig'); // 父组件绑定的事件
    },
    configDelete() {
      // this.deleteDialogVisible = false;
      this.$emit('configDeletedig'); // 父组件绑定的事件
    },
  },
};
</script>

<style lang="scss" scoped>
.configmodel ::v-deep .el-dialog {
  margin: 0px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;

  .el-dialog__header {
    width: 480px;
    height: 60px;
    background: #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    .el-dialog__title {
      color: #111;
      font-size: 16px;
    }
    .el-dialog__headerbtn {
      font-size: 24px;
      .el-dialog__close {
        color: #808080;
      }
    }
  }
  .el-dialog__body {
    padding: 20px 20px 30px 20px;
    .content {
      display: flex;
      justify-content: left;
      .imgmodel {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
        line-height: 16px;
      }
    }
    .btnmodel {
      margin-top: 20px;
      display: flex;
      justify-content: right;
      .btn {
        padding: 8px 15px;
        line-height: 22px;
        color: #666;
        font-size: 14px;
        border: 1px solid #ccc;
        cursor: pointer;
        &.sure {
          margin-right: 20px;
          color: #fff;
          border: 1px solid #026bff;
          background-color: #026bff;
        }
      }
    }
  }
}
</style>

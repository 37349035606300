/**
 * 超时登出检测器
 * 当用户长时间无动态操作后，自动登出
 *
 * @param expiredTime 自动登出时间（用户多长时间无动态操作后自动登出）
 * @param detectTime 检测器间隔多少 ms 检查一次
 * @param isCached 是否需要存储到 localStorage
 */

import router from '@/router/index';
import Vue from 'vue';
export class LogoutDetector {
  constructor(
    expiredTime = 60 * 60 * 1000,
    detectTime = 1000,
    isCached = true
  ) {
    this.expiredTime = expiredTime; // 自动登出时间（用户多长时间无动态操作后自动登出）
    this.detectTime = detectTime; // 检查是否要自动登出的时间间隔
    this.isCached = isCached;

    this.lastTime = localStorage.getItem('xm_last_dynamic_operation_time'); // 用户上一次动态操作的时间点
    if (!this.lastTime) {
      // 如果无上次动态操作记录，则置为当前时间
      this.lastTime = Date.now();
      if (this.isCached) {
        localStorage.setItem('xm_last_dynamic_operation_time', this.lastTime);
      }
    }
    this.logoutTimer = null;
  }
  /**
   * 返回一个检测当前是否已超时的工具函数
   * @returns 检测函数
   */
  getDetector() {
    let that = this;
    return function () {
      let currTime = Date.now();
      let lastTime = localStorage.getItem('xm_last_dynamic_operation_time');
      let expiredTime = that.expiredTime;

      let timeSpan = currTime - lastTime;

      if (process.env.NODE_ENV === 'development') {
        // console.log('定时检测', timeSpan, expiredTime);
      }

      if (timeSpan >= expiredTime) {
        that.logout();
      }
    };
  }
  /**
   * 返回一个更新最新动态操作时间点的工具函数
   * @returns 更新时间函数
   */
  getRecorder() {
    let that = this;
    return function () {
      that.lastTime = Date.now();
      if (that.isCached) {
        localStorage.setItem('xm_last_dynamic_operation_time', that.lastTime);
      }
    };
  }
  /**
   * 开启检测
   */
  start() {
    if (this.logoutTimer) {
      clearInterval(this.logoutTimer);
      this.logoutTimer = null;
    }

    const detector = this.getDetector();
    const detectTime = this.detectTime;
    this.logoutTimer = setInterval(detector, detectTime);
  }
  /**
   * 关闭检测
   */
  close() {
    if (this.logoutTimer) {
      clearInterval(this.logoutTimer);
      this.logoutTimer = null;
    }
  }
  /**
   * 超时登出
   */
  logout() {
    console.log('执行超时登出');
    window.alert('页面长时间未检测到操作，登录状态失效，请重新登录');
    // 关闭检测器
    this.close();
    // 清空token和用户数据
    localStorage.removeItem('token3');
    localStorage.removeItem('myAuths');
    localStorage.removeItem('im_token');
    localStorage.removeItem('admin_id');
    localStorage.removeItem('real_name');
    if (this.isCached) {
      localStorage.removeItem('xm_last_dynamic_operation_time');
    }
    // 销毁 nim 实例
    if (Vue.prototype.$nim) {
      Vue.prototype.$nim.destroy();
      Vue.prototype.$nim = null;
    }
    sessionStorage.clear();

    // 跳转登录页面
    // 换用 router 跳转，避免出现超时登出后再登录，结果登录失败的问题
    // window.location.assign('/login');
    router.push('/login');
  }
}

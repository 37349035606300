<template>
  <div class="xm-show-file-box" :class="{ 'show-file-no-title': !showTitle }">
    <div v-if="showFileBox" class="file-box">
      <div class="success-list" v-for="(item, index) in fileList" :key="index">
        <div class="left-side flex" @click="showPreviewimgFunc(item.link)">
          <!-- 悬浮小图放大 -->
          <XmPreviewImage
            v-if="item.type == 'image' && needHoverZoom"
            :width="width"
            :height="height"
            :imgSrc="item.preview"
          ></XmPreviewImage>
          <!-- 纯图片展示 -->
          <img
            v-if="item.type == 'image' && !needHoverZoom"
            :style="{ width: width, height: height }"
            :src="item.preview"
            @error="handleImgError"
            alt=""
            class="pointer"
          />
          <!-- 视频 -->
          <video
            :style="{ width: width, height: height }"
            v-if="item.type == 'video'"
            :src="item.preview"
            class="video-width"
          ></video>
          <!-- excel/pdf/word/zip/ppt -->
          <img
            :style="{ width: width, height: height }"
            v-if="item.type != 'image' && item.type != 'video'"
            :src="item.type | uploadFileType"
            @error="handleImgError"
            alt=""
          />
          <div
            v-if="showTitle"
            class="file-name overflow-line"
            :title="item.name"
          >
            {{ item.name }}
          </div>
          <div
            v-if="showDownloaded"
            class="down-btn"
            @click.stop="handleDown(item)"
          >
            下载
          </div>
        </div>
      </div>
    </div>
    <!-- 文件列表为空，显示“--” -->
    <div v-else>--</div>

    <!-- 预览图片 -->
    <XmPreviewimg
      :showurl="previewImgUrl"
      :isRote="isPicRote"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    ></XmPreviewimg>
    <!-- 预览文件 -->
    <XmPreviewdoc
      :showurl="previewImgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    ></XmPreviewdoc>
  </div>
</template>
<script>
export default {
  name: 'XmShowFileBox',
  props: {
    /**
     * 需要展示的文件
     * 字符串
     * 逗号分隔
     * 要拼好路径的
     */
    showFile: {
      type: String,
      default: '',
    },
    // 是否需要展示文件名
    showTitle: {
      type: Boolean,
      default: true,
    },
    width: {
      // 小图的宽度
      type: String,
      default: '22px',
    },
    height: {
      // 小图的高度
      type: String,
      default: '22px',
    },
    // 是否需要下载按钮
    showDownloaded: {
      type: Boolean,
      default: false,
    },
    // 预览图片是否需要旋转
    isPicRote: {
      type: Boolean,
      default: false,
    },
    // 是否需要悬浮小图放大
    needHoverZoom: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    // 父组件传过来要展示的文件们
    showFile: {
      immediate: true,
      handler(val) {
        if (val !== '') {
          this.handleFile(val);
        }
      },
    },
  },
  data() {
    return {
      fileList: [], // 传递过来的文件数组

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识
    };
  },
  computed: {
    // 当前是否有文件列表，否则展示 --
    showFileBox() {
      return this.fileList?.length > 0;
    },
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/assets/material/Excel.svg');
      }
      if (val == 'pdf') {
        return require('@/assets/material/pdf.svg');
      }
      if (val == 'word') {
        return require('@/assets/material/word.svg');
      }
      if (val == 'zip') {
        return require('@/assets/material/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/assets/material/ppt.svg');
      }
    },
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(key) {
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    // 处理传递的文件们
    handleFile(val) {
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;
    },
    // 点击下载按钮
    handleDown(item) {
      this.down(item.preview, item.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.show-file-no-title > .file-box {
  display: flex;
  .success-list {
    margin-top: 0px !important;
    margin-right: 10px;
  }
}
.show-file-no-title.xm-show-file-box {
  padding: 0;
}
.xm-show-file-box {
  padding: 9px 0;

  .success-list {
    margin-top: 10px;

    .left-side {
      .xm-preview-image {
        cursor: pointer;
      }

      .file-name:hover {
        color: #026bff !important;
        cursor: pointer;
      }

      img {
        width: 22px;
        height: 22px;
        object-fit: contain;
      }

      .video-width {
        width: 22px;
        height: 22px;
        object-fit: contain;
      }
    }
    .file-name {
      margin-left: 10px;
      width: fit-content;
    }
    .down-btn {
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: 400;
      color: #026bff;
      line-height: 22px;
      margin-left: 10px;
      cursor: pointer;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  ::v-deep img {
    object-fit: contain !important;
  }
}
</style>
